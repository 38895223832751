
// Hover dropdowns on navbar
@media (min-width: map_get($grid-breakpoints, 'lg')) and (hover: hover) {
    //Reset
    .navbar .dropdown-toggle:focus {
        outline: none;
    }

    //Hover border for theme
    .nav-link {
        border-bottom: 4px solid transparent;
    }

    .nav-item:hover .nav-link,
    .nav-link:active,
    .nav-link:focus {
        border-bottom-color: $prussian-blue;
    }

    //Make bottom border flush against navbar bottom
    .navbar {
        padding-top: 0;
        padding-bottom: 0;
    }
    .navbar > .container, .navbar > .container-fluid {
        align-items: stretch;
    }
    .navbar-brand {
        margin-top: .5rem;
        margin-bottom: .5rem;
    }
    .navbar-nav {
        height: 100%;
    }
    .nav-link {
        height: 100%;
        display: flex;
        align-items: flex-end;
    }

    //Theme-specific
    .navbar .dropdown-toggle:after {
        display: none;
    }

    .navbar {
        .dropdown-item:focus, .dropdown-item:hover {
            background-color: transparent;
        }
    }

    //Bottom border should not extend past text
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-left: 0;
        padding-right: 0;
    }

    //Due to above, add back missing padding
    .navbar-expand-lg .navbar-nav .nav-item {
        padding-left: 1.75rem;
        padding-right: 1.75rem;
    }

    //Dropdown positioning for megamenus
    .bootstrap-megamenu {
        position: static;
    }
    .bootstrap-megamenu .dropdown-menu {
        width: 100vw;
        background-color: transparent;
        margin-top: 0;
    }
    .navbar .dropdown-menu {
        border: 0;
        font-weight: 500;
        margin-top: 0; //Otherwise, that gap becomes a tricky mouseleave event
        padding-top: 1.5rem;
        padding-bottom: 1.25rem;
    }

    .bootstrap-megamenu .dropdown-menu {
        /*
        Since this is now transparent so that it can contain a
        white container, padding is no longer needed.
         */
        padding: 0;
    }
    .bootstrap-megamenu .dropdown-item {
        /* Since there are columns limiting widths, nowrap is not needed */
        white-space: normal;
    }

    //Brute-force box shadow that shows over dropdowns instead of messing with z-indexes
    .navbar::before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        z-index: $zindex-dropdown + 1;
        box-shadow: 0px 5px 5px 0px rgba(7, 32, 49, 0.29);
        height: 6px; //fixes box shadow not appearing
    }

    .bootstrap-megamenu .container {
        background-color: #fff;
    }
}

//Theme stuff
h2, .h2 {
    color: $cobalt;
    text-transform: uppercase;
}

.navbar .dropdown-item {
    font-weight: 500;
}

.link-gray {
    color: $gray-200;

    @include hover {
        color: $prussian-blue;
    }
}

.active .link-gray {
    color: $prussian-blue;
}

.link-prussian-blue {
    color: $prussian-blue;

    @include hover {
        color: $gray-200;
    }
}

.active .link-primary {
    color: $primary;
}

.btn {
    font-style: italic;
}

.breadcrumb-item:last-child {
    width: 100%;
    padding-left: 0;
}

.breadcrumb-item:last-child::before {
    display: none;
}
