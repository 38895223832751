// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

@import "../../theme.json";

// Color system
$gray-100: map_get($colors, 'gray100');
$gray-200: map_get($colors, 'gray200');
$gray-300: map_get($colors, 'gray300');
$gray-400: map_get($colors, 'gray400');

$grays: ();
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400
  ),
  $grays
);

$prussian-blue:        map_get($colors, 'prussian-blue');
$cobalt:               map_get($colors, 'cobalt');
$gray-blue:            map_get($colors, 'gray-blue');
$bright-blue:          map_get($colors, 'bright-blue');
$light-bright-blue:    map_get($colors, 'light-bright-blue');
$bronze:               map_get($colors, 'bronze');
$light-bronze:         map_get($colors, 'light-bronze');
$green:                map_get($colors, 'cucumber');
$light-green:          map_get($colors, 'light-cucumber');
$cyan:                 map_get($colors, 'cyan');
$light-cyan:           map_get($colors, 'light-cyan');

//$colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
  (
    "gray":                $gray-200,
    "gray-dark":           $gray-400
  ),
  $colors
);

$primary:       $cobalt;
$secondary:     $prussian-blue;
$success:       $green;
$info:          $bright-blue;
$warning:       $light-bronze;
$danger:        $bronze;
$light:         $gray-100;
$dark:          $gray-400;

$theme-colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark
  ),
  $theme-colors
);

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold:  150;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark:             $gray-400;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem;

// Body
//
// Settings for the `<body>` element.

$body-color:                $gray-400 !default;

// Links
//
// Style anchor elements.

$link-color:                              $cobalt;
$link-hover-color:                        $prussian-blue;
$link-hover-decoration:                   none;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15%;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom:   1rem;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

// Components
//
// Define common padding and border radius sizes and more.

$border-radius:               0;
$border-radius-lg:            $border-radius;
$border-radius-sm:            $border-radius;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif:      'Roboto', -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base:            $font-family-sans-serif;
// stylelint-enable value-keyword-case

$font-size-base:              1rem; // Assumes the browser default, typically `16px`
$font-size-lg:                $font-size-base * 1.25;
$font-size-sm:                $font-size-base * .875;

$font-weight-bold:            700;
$line-height-base:            1.5;

$h1-font-size:                $font-size-base * 2.5;
//$h2-font-size:                $font-size-base * 2 !default;
$h2-font-size:                $font-size-base * 1.5;
//TODO: in this new world of smaller h2s, where do these fit?
$h3-font-size:                $font-size-base * 1.25;
$h4-font-size:                $font-size-base * 1.25;
$h5-font-size:                $font-size-base * 1.25;
$h6-font-size:                $font-size-base;

$headings-margin-bottom:      $spacer / 2;
$headings-font-family:        null;
$headings-font-weight:        500;
$headings-line-height:        1.2;
$headings-color:              null;

//$display1-size:               6rem;
//$display2-size:               5.5rem;
//$display3-size:               4.5rem;
//$display4-size:               3.5rem;
//
//$display1-weight:             300 !default;
//$display2-weight:             300 !default;
//$display3-weight:             300 !default;
//$display4-weight:             300 !default;
//$display-line-height:         $headings-line-height !default;

//$lead-font-size:              $font-size-base * 1.25 !default;
//$lead-font-weight:            300 !default;

//$small-font-size:             80% !default;

$text-muted:                  $gray-400;

$blockquote-small-color:      $gray-400;
//$blockquote-small-font-size:  $small-font-size !default;
//$blockquote-font-size:        $font-size-base * 1.25 !default;

//$hr-border-color:             rgba($black, .1) !default;
//$hr-border-width:             $border-width !default;

//$mark-padding:                .2em !default;

//$dt-font-weight:              $font-weight-bold !default;

//$kbd-box-shadow:              inset 0 -.1rem 0 rgba($black, .25) !default;
//$nested-kbd-font-weight:      $font-weight-bold !default;
//
//$list-inline-padding:         .5rem !default;
//
//$mark-bg:                     #fcf8e3 !default;
//
//$hr-margin-y:                 $spacer !default;


// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.


// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-font-weight:             $font-weight-bold;
//$btn-box-shadow:              inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075) !default;
//$btn-focus-width:             $input-btn-focus-width !default;
//$btn-focus-box-shadow:        $input-btn-focus-box-shadow !default;
//$btn-disabled-opacity:        .65 !default;
//$btn-active-box-shadow:       inset 0 3px 5px rgba($black, .125) !default;

//$btn-link-disabled-color:     $gray-600 !default;

//$btn-block-spacing-y:         .5rem !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:           0;
$btn-border-radius-lg:        0;
$btn-border-radius-sm:        0;

//$btn-transition:              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;


// Forms

// Form validation

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

// Navs

//$nav-link-padding-y:                .5rem !default;
//$nav-link-padding-x:                1rem !default;
//$nav-link-disabled-color:           $gray-600 !default;
//
//$nav-tabs-border-color:             $gray-300 !default;
//$nav-tabs-border-width:             $border-width !default;
//$nav-tabs-border-radius:            $border-radius !default;
//$nav-tabs-link-hover-border-color:  $gray-200 $gray-200 $nav-tabs-border-color !default;
//$nav-tabs-link-active-color:        $gray-700 !default;
//$nav-tabs-link-active-bg:           $body-bg !default;
//$nav-tabs-link-active-border-color: $gray-300 $gray-300 $nav-tabs-link-active-bg !default;
//
//$nav-pills-border-radius:           $border-radius !default;
//$nav-pills-link-active-color:       $component-active-color !default;
//$nav-pills-link-active-bg:          $component-active-bg !default;
//
//$nav-divider-color:                 $gray-200 !default;
//$nav-divider-margin-y:              $spacer / 2 !default;


// Navbar

//$navbar-padding-y:                  $spacer / 2 !default;
//$navbar-padding-x:                  $spacer !default;
//
//$navbar-nav-link-padding-x:         .5rem !default;
//
//$navbar-brand-font-size:            $font-size-lg !default;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
//$nav-link-height:                   $font-size-base * $line-height-base + $nav-link-padding-y * 2 !default;
//$navbar-brand-height:               $navbar-brand-font-size * $line-height-base !default;
//$navbar-brand-padding-y:            ($nav-link-height - $navbar-brand-height) / 2 !default;
//
//$navbar-toggler-padding-y:          .25rem !default;
//$navbar-toggler-padding-x:          .75rem !default;
//$navbar-toggler-font-size:          $font-size-lg !default;
//$navbar-toggler-border-radius:      $btn-border-radius !default;
//
//$navbar-dark-color:                 rgba($white, .5) !default;
//$navbar-dark-hover-color:           rgba($white, .75) !default;
//$navbar-dark-active-color:          $white !default;
//$navbar-dark-disabled-color:        rgba($white, .25) !default;
//$navbar-dark-toggler-icon-bg:       str-replace(url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='#{$navbar-dark-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"), "#", "%23") !default;
//$navbar-dark-toggler-border-color:  rgba($white, .1) !default;

$navbar-light-color:                $prussian-blue;
$navbar-light-hover-color:          $prussian-blue;
$navbar-light-active-color:         $prussian-blue;
//$navbar-light-toggler-icon-bg:      str-replace(url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"), "#", "%23") !default;

//$navbar-light-brand-color:                $navbar-light-active-color !default;
//$navbar-light-brand-hover-color:          $navbar-light-active-color !default;
//$navbar-dark-brand-color:                 $navbar-dark-active-color !default;
//$navbar-dark-brand-hover-color:           $navbar-dark-active-color !default;


// Dropdowns
//
// Dropdown menu container and contents.

//$dropdown-min-width:                10rem !default;
//$dropdown-padding-y:                .5rem !default;
//$dropdown-spacer:                   .125rem !default;
//$dropdown-font-size:                $font-size-base !default;
//$dropdown-color:                    $body-color !default;
//$dropdown-bg:                       $white !default;
//$dropdown-border-radius:            $border-radius;
//$dropdown-border-width:             $border-width !default;
//$dropdown-inner-border-radius:      calc(#{$dropdown-border-radius} - #{$dropdown-border-width}) !default;
//$dropdown-divider-bg:               $gray-200 !default;
//$dropdown-divider-margin-y:         $nav-divider-margin-y !default;
//
$dropdown-link-color:                 $gray-200;
$dropdown-link-hover-color:           $prussian-blue;
//$dropdown-link-hover-bg:            $gray-100 !default;
//
//$dropdown-link-active-color:        $component-active-color !default;
//$dropdown-link-active-bg:           $component-active-bg !default;
//
//$dropdown-link-disabled-color:      $gray-600 !default;
//
//$dropdown-item-padding-y:           .25rem !default;
//$dropdown-item-padding-x:           1.5rem !default;
//
//$dropdown-header-color:             $gray-600 !default;


// Pagination

// Jumbotron

// Cards

// Tooltips

// Popovers


// Toasts


// Badges


// Modals


// Alerts
//
// Define alert colors, border radius, and padding.


// Progress bars

// List group


// Image thumbnails

// Figures


// Breadcrumbs

//$breadcrumb-padding-y:              .75rem !default;
//$breadcrumb-padding-x:              1rem !default;
//$breadcrumb-item-padding:           .5rem !default;

//$breadcrumb-margin-bottom:          1rem !default;

$breadcrumb-bg:                     transparent;
//$breadcrumb-divider-color:          $gray-600 !default;
//$breadcrumb-active-color:           $gray-600 !default;
//$breadcrumb-divider:                quote("/") !default;
//
//$breadcrumb-border-radius:          $border-radius !default;


// Carousel


// Spinners

//$spinner-width:         2rem !default;
//$spinner-height:        $spinner-width !default;
//$spinner-border-width:  .25em !default;
//
//$spinner-width-sm:        1rem !default;
//$spinner-height-sm:       $spinner-width-sm !default;
//$spinner-border-width-sm: .2em !default;


// Close

//$close-font-size:                   $font-size-base * 1.5 !default;
//$close-font-weight:                 $font-weight-bold !default;
//$close-color:                       $black !default;
//$close-text-shadow:                 0 1px 0 $white !default;


// Code

//$code-font-size:                    87.5% !default;
$code-color:                        $light-cyan;

//$kbd-padding-y:                     .2rem !default;
//$kbd-padding-x:                     .4rem !default;
//$kbd-font-size:                     $code-font-size !default;
//$kbd-color:                         $white !default;
$kbd-bg:                            $gray-400 !default;

//$pre-color:                         $gray-900 !default;
//$pre-scrollable-max-height:         340px !default;


// Utilities

// Printing

//$print-page-size:                   a3 !default;
//$print-body-min-width:              map-get($grid-breakpoints, "lg") !default;
